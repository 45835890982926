import React, { Component } from 'react';
import '../../assets/css/custom.css';
import InfoMarker from './markers/InfoMarker';
import PlusMarker from './markers/PlusMarker';
import WorldMarker from './markers/WorldMarker';
import PlayMarker from './markers/PlayMarker';
import HatChefMarker from './markers/HatChefMarker';
import SpecsMarker from './markers/SpecsMarker';
import PromoMarker from './markers/PromoMarker';
import ShowPriceMarker from './markers/ShowPriceMarker';
import { isMobile, isTablet } from 'react-device-detect';
import { serverResponse } from '../../util/fakeServer';

var marker = serverResponse.config.marker ? serverResponse.config.marker : "circle_black";
const selected = require("assets/images/" + marker + ".svg");

export default class CustomProduct extends Component {

    state = {
        childOpen: false
    }

    navMarkers = (newMarker) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della modal di tipo newmarker
        /*axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });*/
        var element = document.getElementById(newMarker + "-" + this.props.info.item_id);
        element.click();
    }

    render() {

        const styleProduct = {
            position: "absolute",
            display: this.props.info.blueprint !== null ? "flex" : "none",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            top: this.props.info.blueprint !== null ? this.props.info.blueprint.top + "%" : "0%",
            left: this.props.info.blueprint !== null ? this.props.info.blueprint.left + "%" : "0%",
            width: this.props.info.blueprint !== null ? this.props.info.blueprint.width + "%" : "0%",
            height: this.props.info.blueprint !== null ? this.props.info.blueprint.height + "%" : "0%",
        }

        let markers = this.props.info.markers.map((element, key) => {
            switch (element.type) {
                case "plus": {
                    if (!this.props.info.pam)
                        return <PlusMarker disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                    else
                        break
                }
                case "info": {
                    return <InfoMarker disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "world": {
                    return <WorldMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "hat-chef": {
                    return <HatChefMarker disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "play": {
                    return <PlayMarker disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "specs": {
                    return <SpecsMarker disableSwipe={this.props.disableSwipe} enableSwipe={this.props.enableSwipe} key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "promo": {
                    return <PromoMarker key={key} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                }
                case "video": {
                    return <div key={key} id="videoIper" ><video autoPlay="autolplay" defaultmuted="true" muted playsInline loop style={{width: '100%'}}><source src={this.props.info.markers[0].data} /></video></div>
                }
                default: return null
            }
        });

        let markers_2 = [];
        if (this.props.info.hidden_price) {
            markers_2 = this.props.info.markers.map((element, key) => {
                switch (element.type) {
                    case "play": {
                        return <ShowPriceMarker key={key + 10} info={this.props.info} markerInfo={element} leafletSwipe={this.props.leafletSwipe} navMarkers={this.navMarkers} setSwipe={this.props.setSwipe} isSwipeDisabed={this.props.isSwipeDisabed} />
                    }
                    default: return null
                }
            });
        }
        markers = markers.concat(markers_2);

        var id_select = "selected-" + this.props.info.item_id;
        var id_div = "div-" + this.props.info.item_id;

        return (
            <div id={id_div} className="customProduct" style={styleProduct} onClick={(e) => {
                //controllo che sia stato cliccato il quadrato
                if (e.target.classList.contains("customProduct")) {
                    var plus = document.getElementById("plus-" + this.props.info.item_id);
                    var showprice = document.getElementById("showprice-" + this.props.info.item_id);

                    var markers_type = this.props.info.markers.map((element, key) => { return element.type });
                    if (this.props.info.hidden_price) {
                        showprice.click();
                    }
                    else {
                        if(this.props.info.pam) {
                            document.getElementById("world-" + this.props.info.item_id).click();
                        }
                        else if (markers_type.includes("plus")) {
                            plus.click();
                        }
                        else {
                            var interactivity = null;
                            switch (markers_type[0]) {
                                case "info": {
                                    interactivity = document.getElementById("info-" + this.props.info.item_id);
                                    break;
                                }
                                case "world": {
                                    interactivity = document.getElementById("world-" + this.props.info.item_id);
                                    break;
                                }
                                case "hat-chef": {
                                    interactivity = document.getElementById("hatchef-" + this.props.info.item_id);
                                    break;
                                }
                                case "play": {
                                    interactivity = document.getElementById("play-" + this.props.info.item_id);
                                    break;
                                }
                                case "specs": {
                                    interactivity = document.getElementById("specs-" + this.props.info.item_id);
                                    break;
                                }
                                case "promo": {
                                    interactivity = document.getElementById("promo-" + this.props.info.item_id);
                                    break;
                                }
                                default: return null
                            }
                            if (interactivity != null) {
                                interactivity.click();
                            }
                        }
                    }
                }
            }}>
                {serverResponse.config.marker_product_in_list && <img src={selected} style={{position: "absolute", display: 'none'}} id={id_select} onClick={() => document.getElementById(id_div).click()} />}
                {markers}
            </div>
        );
    }
}