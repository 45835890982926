const leaflets = {
    "demo": {
        "config": {
            "primary_color": "#00416B",
            "secondary_color": "#D62725",
            "tertiary_color": "#008D36",
            "hover_color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": '/media/images/logo.png',
            "serverPath": "fruitviaggi.interattivo.net/",
            "pdfPath": "/media/pdf/catalogo.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo catalogo di Interattivo!",
            "shareFlyerURL": "https://fruitviaggi.interattivo.net?v=588",
            "shareProductSentence": "Guarda questo fantastico Catalogo!",
            "ogTitleMeta": "Fruit village | Catalogo Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro nuovo catalogo.",
            "ogImageMeta": "/media/images/share_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-38",
            "release_id": "1",
            "send_grocery_list_to_market": true,
            "grocery_list_min_value": 10,
            "client_id": 38,
            "signboard_id": 60,
            "has_pages": true,
            "type": "catalog",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true,
            "pager": "buttons",
            "hide_share_product": false,
            "hide_grocery_list": true,
            "version": 588
        },
        "leaflet": {
            "name": "Demo Fruit Villages",
            "code": "588",
            "id_campaign": "588",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/Indice-demo-fruitVillage_8X8GREh_K3oPYpx.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 28.772590288943874,
                            "left": 9.296094459582203,
                            "width": 81.4078110808356,
                            "height": 5.306772192321145
                        }
                    },
                    {
                        "page": 3,
                        "blueprint": {
                            "top": 35.67540591110554,
                            "left": 9.167574931880115,
                            "width": 81.4078110808356,
                            "height": 5.760904799042308
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 41.43631071014786,
                            "left": 9.167574931880115,
                            "width": 81.4078110808356,
                            "height": 6.319022539740616
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 51.6088811007019,
                            "left": 9.296094459582203,
                            "width": 81.4078110808356,
                            "height": 5.774063411675222
                        }
                    },
                    {
                        "page": 9,
                        "blueprint": {
                            "top": 58.965829329584736,
                            "left": 9.424613987284292,
                            "width": 81.4078110808356,
                            "height": 5.774063411675222
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 64.73989274125994,
                            "left": 9.424613987284292,
                            "width": 81.4078110808356,
                            "height": 6.396690448451935
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "https://www.fruitvillage.it/index.php?name=Hotels&op=hotels_details&hotel_id=574&reset=true",
                        "target": "_blank",
                        "blueprint": {
                            "top": 74.8115554426673,
                            "left": 29.316067653276956,
                            "width": 41.9661733615222,
                            "height": 7.670758036441327
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "tel:0815068048",
                        "target": "_blank",
                        "blueprint": {
                            "top": 82.63396559065883,
                            "left": 28.867336152219874,
                            "width": 43.0132135306554,
                            "height": 5.768009622065007
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Sport e Tempo Libero",
                    "subcategories": [
                        "Altro"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_1_JwmwRYq.jpg",
                    "products": []
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_2_hpp1cqh.jpg",
                    "products": []
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_3_JbjP8ci.jpg",
                    "products": [
                        {
                            "item_id": "cstm04819592",
                            "field1": "Fruitvillage prime paestum",
                            "field2": "",
                            "field3": "Il villaggio è situato a soli 5 km dalla zona archeologica di Paestum.",
                            "field4": "",
                            "description": "Fruitvillage prime paestum Il villaggio è situato a soli 5 km dalla zona archeologica di Paestum.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 40.50524839622973,
                                "left": 49.3174899768278,
                                "width": 50.6825100231722,
                                "height": 33.71868390798109
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Veduta_di_Paestum_2010_UsRE1g2.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/templi-di-paestum-Hera-Nettuno-Basilica_vmCSKLD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/DSC_8559_Xxrb7M1.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_q6FyOdG_2GOsqvS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm37531288",
                            "field1": "Video",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Video",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.6840275821909,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 21.20141342756184
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_P9QGeCr_7uKvYgU.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/video/Fruit_Village_Coral_Beach_-_Paestum_jaBxAHx.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_4_S3YmWsC.jpg",
                    "products": [
                        {
                            "item_id": "cstm42500505",
                            "field1": "Camere",
                            "field2": "Le 92 camere sono dotate di aria condizionata, mini frigo, servizi con doccia e asciugacapelli, tv lcd",
                            "field3": "e sono disposte al piano terra in costruzione a schiera.",
                            "field4": "Nella struttura sono presenti anche camere per persone diversamente abili.",
                            "description": "Camere Le 92 camere sono dotate di aria condizionata, mini frigo, servizi con doccia e asciugacapelli, tv lcd e sono disposte al piano terra in costruzione a schiera. Nella struttura sono presenti anche camere per persone diversamente abili.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.598394121158067,
                                "left": 0.0,
                                "width": 49.630057803468205,
                                "height": 33.01901137232741
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8787-300x300_TOszwJs.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8474-copia-300x300_xZmT0gJ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8366-300x300_kxiVtvC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_pW7kZsE_Wvf3saQ.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm97705806",
                            "field1": "Struttura",
                            "field2": "Il Fruit Village Paestum Coral Beach è un villaggio di nuovissima realizzazione,",
                            "field3": "situato a soli 5 km dalla zona archeologica degli scavi di Paestum",
                            "field4": "",
                            "description": "Struttura Il Fruit Village Paestum Coral Beach è un villaggio di nuovissima realizzazione, situato a soli 5 km dalla zona archeologica degli scavi di Paestum",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.762363519588952,
                                "left": 50.40895953757226,
                                "width": 49.59104046242775,
                                "height": 47.099120936948076
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageBEACH-CORAL-SPIAGGIA-300x300_07jlsYD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillagecamere-300x300_IjRVs2R.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillagepiscina-300x300_PLbbwn4.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_0430-copia-300x300_Zz7G1HS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_u6jPt0M_jWh6Ddd.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm98473498",
                            "field1": "Video",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Video",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 59.516168789137765,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 40.48383121086223
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_7LEIzK2_7AR8L2F.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "video",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/video/SIGLA_FOGLIOLINA_WORLD.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_5_uNvM3l0.jpg",
                    "products": [
                        {
                            "item_id": "cstm92241083",
                            "field1": "Svago",
                            "field2": "Un gruppo di professionisti del divertimento ti accompagnerà ",
                            "field3": "per rendere la tua vacanza un'esperienza piena di momenti indimenticabili.",
                            "field4": "",
                            "description": "Svago Un gruppo di professionisti del divertimento ti accompagnerà  per rendere la tua vacanza un'esperienza piena di momenti indimenticabili.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.67673397860468,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 27.63861801353263
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_0430-copia-300x300_QZs31Xs_xMMQ0Ox.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/9390437d0c80245b973d3b8e851170dd_750x400_ULAUXXC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cd880a9864c1ca100e2406848b67ec4c_750x400_aFkGyzU.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_ehsjVYE_kjdpV4G.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_6_pPGy6cc.jpg",
                    "products": [
                        {
                            "item_id": "cstm47091890",
                            "field1": "Camere",
                            "field2": "Le 92 camere sono dotate di aria condizionata, mini frigo, servizi con doccia e asciugacapelli, tv lcd ",
                            "field3": "e sono disposte al piano terra in costruzione a schiera.",
                            "field4": "Nella struttura sono presenti anche camere per persone diversamente abili",
                            "description": "Camere Le 92 camere sono dotate di aria condizionata, mini frigo, servizi con doccia e asciugacapelli, tv lcd  e sono disposte al piano terra in costruzione a schiera. Nella struttura sono presenti anche camere per persone diversamente abili",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.598394121158067,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 48.34763610011924
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8474-copia-300x300_iEoqLHn_lTPhuHd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8787-300x300_qeMUz6n_Z9g0yZ7.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/Coral-offertefruitvillageZ6A_8366-300x300_oJ7ndfl_GOBk6WS.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_OytAdWl_a8c93pI.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm16094726",
                            "field1": "Richiedi il tuo preventivo",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Richiedi il tuo preventivo",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 91.3723004193511,
                                "left": 32.59649967886962,
                                "width": 34.897880539499035,
                                "height": 6.080065894448458
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_wEJMLjf_ZtxZjwy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.fruitvillage.it/index.php?name=Hotels&op=hotels_details&hotel_id=574&reset=true&titolo=FRUIT+VILLAGE+PAESTUM+CORAL+BEACH"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm99194038",
                            "field1": "Prenota con bonus vacanze",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Prenota con bonus vacanze",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 57.71783866710821,
                                "left": 73.31069364161849,
                                "width": 18.81305229564317,
                                "height": 10.190526904704559
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_lUIhNtN_LORkss6.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.fruitvillage.it/index.php?name=Hotels&op=hotels_details&hotel_id=574&reset=true"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm39569472",
                            "field1": "Spiaggia",
                            "field2": "La struttura dista 150 mt dalla spiaggia di sabbia chiara e fine,",
                            "field3": "attrezzata con ombrellone e due lettini a camera,",
                            "field4": "raggiungibile con attraversamento stradale oppure con servizio navetta",
                            "description": "Spiaggia La struttura dista 150 mt dalla spiaggia di sabbia chiara e fine, attrezzata con ombrellone e due lettini a camera, raggiungibile con attraversamento stradale oppure con servizio navetta",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 64.05481082472804,
                                "left": 3.7251649928843213,
                                "width": 32.748873270239386,
                                "height": 26.26845911671584
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/903e3aece968c6f0a45e9869a99fc824_750x400_Ro7CIVe.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/b07bf9a4c90503070c268db108805b8f_750x400_r7Tokuv.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/2a3c38ba504af9738a4d56243eb57669_750x400_a6995W0.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_7LtFkqM_AcpElkE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_7_cDwpQhZ.jpg",
                    "products": []
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_8_m4iRqql.jpg",
                    "products": []
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_9_iKX3rNZ.jpg",
                    "products": [
                        {
                            "item_id": "cstm74994513",
                            "field1": "Struttura",
                            "field2": "Il fruit Village Oasi del Cilento si trova ad Ascea Marina,",
                            "field3": "e offre la possibilità di vivere gli aspetti più interessanti del territorio,",
                            "field4": "dai suggestivi scavi di Velia alle indimenticabili atmosfere naturali e ambientali.",
                            "description": "Struttura Il fruit Village Oasi del Cilento si trova ad Ascea Marina, e offre la possibilità di vivere gli aspetti più interessanti del territorio, dai suggestivi scavi di Velia alle indimenticabili atmosfere naturali e ambientali.",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 40.76214975934039,
                                "left": 49.4395776649431,
                                "width": 50.5604223350569,
                                "height": 33.48319287015293
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento4cb67899-790f-49a3-814d-de425e962b0f-300x300_NSut0Yc.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/newlogo21-300x300_GXF4tPa.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/newlogo23-300x300_UbNKvGK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento9b67e6ed-55be-4732-a6e2-99d7442d3e48-300x300_mzEwWh5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento7425cc33-5733-467a-beb9-f8548c4ffd5a-300x300_mwqsz8u.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_iDkHRzw_j8I9BTx.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_10_BYbIgVy.jpg",
                    "products": [
                        {
                            "item_id": "cstm36452073",
                            "field1": "Camere",
                            "field2": "La struttura è costruita da 50 camere comode e spaziose, ",
                            "field3": "arredate in uno stile semplice ma estremamente gradevole",
                            "field4": "",
                            "description": "Camere La struttura è costruita da 50 camere comode e spaziose,  arredate in uno stile semplice ma estremamente gradevole",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.591093657486855,
                                "left": 0.0,
                                "width": 49.10634397924445,
                                "height": 33.14065314594874
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoc22234c3-eab7-4638-8b46-e895fbe36a34-300x300_sXkux3A.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/newlogo26-1-300x300_JAXMf7q.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentocf8d0d1e-9d56-4081-a4a3-23adfd9b30ba-300x300_gBnFpoQ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_ehaNJrD_KPjTEw8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm92189686",
                            "field1": "Svago",
                            "field2": "",
                            "field3": "Un gruppo di professionisti del divertimento ti accompagnerà per rendere la tua vacanza un'esperienza piena di momenti indimenticabili",
                            "field4": "",
                            "description": "Svago Un gruppo di professionisti del divertimento ti accompagnerà per rendere la tua vacanza un'esperienza piena di momenti indimenticabili",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.484546401192562,
                                "left": 50.306458407453675,
                                "width": 49.693541592546325,
                                "height": 47.337295720075026
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento650c88c8-49dc-4fd7-aa07-748851444816-300x300_1WMSwfq.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/new_animationoasi2-300x300_uUdmQXm.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento86231d1a-2f94-4dca-8c49-afd0fc8e8126-300x300_oCD5yEy.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoca71f783-2c27-4a7e-992b-3d8fcdb67b5b-300x300_UB1hY9z.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_JS44VLO_0t6J7Jy.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm99708223",
                            "field1": "Spiaggia",
                            "field2": "Il Fruit Village Oasi del Cilento dista soltanto circa 250m dalla spiaggia privata di sabbia fine e bianca,",
                            "field3": "attrezzata con docce e servizi privati",
                            "field4": "raggiungibili con una piacevole passeggiata",
                            "description": "Spiaggia Il Fruit Village Oasi del Cilento dista soltanto circa 250m dalla spiaggia privata di sabbia fine e bianca, attrezzata con docce e servizi privati raggiungibili con una piacevole passeggiata",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 59.60179147003702,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 40.39820852996296
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/newlogo24-1-300x300_1hMqsAd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/new_animationoasi-1-300x300_WXDLNQo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/new_animationoasi2-300x300_hIbSCBP_MvyY2Vo.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_zmmewXm_xoX6KrH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_11_D7VjPLx.jpg",
                    "products": [
                        {
                            "item_id": "cstm45090543",
                            "field1": "Piscina",
                            "field2": "Il Fruit Village Ascea Oasi del Cilento mette a disposizione degli ospiti 2 piscine,",
                            "field3": "una per bambini ed una per gli adulti a 5 corsie,",
                            "field4": "completa di ombrelloni, lettini, sdraio, solarium",
                            "description": "Piscina Il Fruit Village Ascea Oasi del Cilento mette a disposizione degli ospiti 2 piscine, una per bambini ed una per gli adulti a 5 corsie, completa di ombrelloni, lettini, sdraio, solarium",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.591095617511142,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 28.088201524052163
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoca71f783-2c27-4a7e-992b-3d8fcdb67b5b-300x300_Yp2GgN0_LCAuraw.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoec6a9a5a-9f3c-4208-93af-5d0183085ffb-300x300_TYpF3fV.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cf8a75024df806291f298df1fb61514f_750x400_1tRTiU8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_qEv6cQA_T9OrRHm.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 12,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/page_12_4mXpwob.jpg",
                    "products": [
                        {
                            "item_id": "cstm42185517",
                            "field1": "Richiedi il tuo preventivo",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Richiedi il tuo preventivo",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 91.97173856662953,
                                "left": 30.77890173410405,
                                "width": 38.29072818743853,
                                "height": 5.138086062941554
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_AlTma43_o8wzX12.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.fruitvillage.it/index.php?name=Hotels&op=hotels_details&hotel_id=540&reset=true&titolo=FRUIT+VILLAGE+ASCEA+OASI+DEL+CILENTO"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm81916978",
                            "field1": "Prenota con bonus vacanza",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Prenota con bonus vacanza",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": true,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 56.40992182618205,
                                "left": 72.87370334299658,
                                "width": 18.539510130346315,
                                "height": 12.18352907411199
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_KWpiK2X_VUGClfK.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.fruitvillage.it/index.php?name=Hotels&op=hotels_details&hotel_id=540&reset=true&titolo=FRUIT+VILLAGE+ASCEA+OASI+DEL+CILENTO"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm63288876",
                            "field1": "Struttura",
                            "field2": "Il fruit Village Oasi del Cilento si trova ad Ascea Marina,",
                            "field3": "e offre la possibilità di vivere gli aspetti più interessanti del territorio,",
                            "field4": "dai suggestivi scavi di Velia alle indimenticabili atmosfere naturali e ambientali",
                            "description": "Struttura Il fruit Village Oasi del Cilento si trova ad Ascea Marina, e offre la possibilità di vivere gli aspetti più interessanti del territorio, dai suggestivi scavi di Velia alle indimenticabili atmosfere naturali e ambientali",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Sport e Tempo Libero",
                            "subcategory": "Altro",
                            "blueprint": {
                                "top": 9.419826735421182,
                                "left": 0.0,
                                "width": 100.0,
                                "height": 46.26348292000701
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento95c179d4-8c60-4e3d-97de-06b93c202f83-300x300_8HOo4zN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilento0521a781-9f10-44f0-8841-4eb34ab6b664-300x300_eyKDO9w.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/newlogo21-300x300_7kzmHod_2mvuJuP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoa73151b9-affd-4bb3-b12b-c157d592a10f-300x300_K8m3uN5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/oasidelcilentoec28fe32-d33e-4984-85f8-d2326b9b9351-300x300_3uPK2YC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/products/cropped_image_sK5vroj_Mern9v5.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 13,
                    "image_file": "https://interattivo.s3.amazonaws.com/URuPh4Rp7i/pages/FruitVillage-endpage_2MN4pFV.png",
                    "products": []
                }
            ]
        }

    }
}

const lefletSwitch = () => {
    return leaflets.demo;
}

export const serverResponse = lefletSwitch();